import react from 'react';
import React, { Component } from 'react';
import './App.css';

class App extends Component {
  state = {
    coords: {
      x: window.innerWidth / 2,
      y: window.innerHeight - 60
    },
    front: 'right',
    playing: true
  }

  render() {
    return (
      <>
        <div className="container">
          <div className="gamespace">
            {this.projectiles.map(i => this.projectileFactory(i.x, i.y))}
            {this.enemies.map(i => this.enemyFactory(i.x, window.innerHeight - 85))}
            <div className="player" style={{ width: this.playerProperties.width, height: this.playerProperties.height, top: this.state.coords.y, left: this.state.coords.x }}>
            </div>
          </div>
          <div className="floor"></div>
        </div>
      </>);
  }

  playerProperties = {
    width: 50,
    height: 50
  }

  projectileFactory = (x, y) => {
    return <div
      style={{
        height: 5,
        width: 15,
        backgroundColor: '#c9b903',
        position: 'absolute',
        left: x,
        top: y
      }}></div>
  }

  enemyFactory = (x, y) => {
    return <div
      style={{
        height: 75,
        width: 75,
        backgroundColor: 'green',
        opacity: .7,
        position: 'absolute',
        top: y,
        left: x
      }}
    ></div>
  }

  projectiles = []

  enemies = []

  maxEnemies = 10

  keysDown = {
    space: false,
    right: false,
    left: false
  }

  maxVelocity = {
    x: 600,
    y: 300,
  }

  acceleration = {
    x: 35,
    y: 70
  }

  velocity = {
    x: 0,
    y: 0
  }

  spawn = () => {
    if (this.enemies.length >= this.maxEnemies) {
      return;
    }

    let side = Math.round(Math.random());

    this.enemies.push(
      {
        x: side ? window.innerWidth - 75 : 0,
        y: window.innerHeight - 85,
        v: side ? -5 : 5
      }
    )
  }

  update = () => {

    this.setState({
      coords: {
        x: Math.max(Math.min(this.state.coords.x + this.velocity.x, window.innerWidth - this.playerProperties.width), 0),
        y: Math.max(Math.min(this.state.coords.y + this.velocity.y + 30, window.innerHeight - this.playerProperties.height) - 10, 0)
      }
    }, () => {
      if (this.keysDown.right) {
        this.velocity.x += this.acceleration.x / 60;
        if (this.velocity.x > this.maxVelocity.x) this.velocity.x = this.maxVelocity.x;
      } else {
        if (this.velocity.x > 0) {
          this.velocity.x = this.velocity.x / 1.1;
        }
      }

      if (this.keysDown.left) {
        this.velocity.x -= this.acceleration.x / 60;
        if (this.velocity.x < -this.maxVelocity.x) this.velocity.x = -this.maxVelocity.x;
      } else {
        if (this.velocity.x < 0) {
          this.velocity.x = this.velocity.x / 1.1;
        }
      }

      if (this.keysDown.space && this.state.coords.y == window.innerHeight - 60) {
        this.velocity.y -= this.acceleration.y;
        if (this.state.coords.y + this.velocity.y > window.innerHeight) {
          this.velocity.y = this.velocity.y / 1.2;
        }
      } else {
        this.velocity.y = this.velocity.y / 1.2;
      }

      if (this.state.coords.x === 0 && this.velocity < 0) {
        this.velocity.x = 0;
      }

      this.projectiles.forEach((elem, i) => {
        elem.x += elem.v;
        if (elem.x > window.innerWidth || elem.x < 0) {
          this.projectiles.splice(i, 1);
        }
      })

      this.enemies.forEach((elem, i) => {
        elem.x += elem.v;
        this.projectiles.forEach((e, j) => {
          if ((e.x >= elem.x && e.x <= elem.x + 75) && (e.y >= elem.y)) {
            this.enemies.splice(i, 1);
            this.projectiles.splice(j, 1);
          }
        });

        if (elem.x < 0 || elem.x > window.innerWidth) {
          elem.v = -elem.v;
        }

        if (((this.state.coords.x <= elem.x + 65 && elem.x <= this.state.coords.x)
          || ((this.state.coords.x + this.playerProperties.width - 10) >= elem.x && this.state.coords.x + this.playerProperties.width <= elem.x + 60))
          && elem.y <= this.state.coords.y
        ) {
          this.setState({
            playing: false
          }, () => {
            return alert('You lose!');
          });
        }
      });
    })

    if(this.state.playing) {
      window.requestAnimationFrame(this.update);
    }
  }

  componentDidMount = () => {

    window.addEventListener('keydown', (e) => {
      if (e.code === "Space") {
        this.keysDown.space = true;
      } else if (e.code === "KeyD" || e.code === "ArrowRight") {
        this.keysDown.right = true;
      } else if (e.code === "KeyA" || e.code === "ArrowLeft") {
        this.keysDown.left = true;
      }
    });

    window.addEventListener('keyup', (e) => {
      if (e.code === "Space") {
        this.keysDown.space = false;
      } else if (e.code === "KeyD" || e.code === "ArrowRight") {
        this.keysDown.right = false;
      } else if (e.code === "KeyA" || e.code === "ArrowLeft") {
        this.keysDown.left = false;
      } else if (e.code === "Enter") {
        this.projectiles.push({
          x: this.state.coords.x,
          y: this.state.coords.y + this.playerProperties.height / 2,
          v: this.velocity.x < 0 ? this.velocity.x - 15 : this.velocity.x + 15
        })
      }
    });

    window.requestAnimationFrame(this.update);

    setInterval(this.spawn, 2000);
  }
}

export default App;